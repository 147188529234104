export const ACTION_SET_AUTH_DATA = 'ACTION_SET_AUTH_DATA';

export const ACTION_REGISTER__REQUEST = 'ACTION_REGISTER__REQUEST';
export const ACTION_REGISTER__SUCCESS = 'ACTION_REGISTER__SUCCESS';
export const ACTION_REGISTER__FAIL = 'ACTION_REGISTER__FAIL';

export const ACTION_AUTH__REQUEST = 'ACTION_AUTH__REQUEST';
export const ACTION_AUTH__SUCCESS = 'ACTION_AUTH__SUCCESS';
export const ACTION_AUTH__FAIL = 'ACTION_AUTH__FAIL';

export const ACTION_FILL_NAME__REQUEST = 'ACTION_FILL_NAME__REQUEST';
export const ACTION_FILL_NAME__SUCCESS = 'ACTION_FILL_NAME__SUCCESS';
export const ACTION_FILL_NAME__FAIL = 'ACTION_FILL_NAME__FAIL';

export const ACTION_LOGOUT__REQUEST = 'ACTION_LOGOUT__REQUEST';
export const ACTION_LOGOUT__SUCCESS = 'ACTION_LOGOUT__SUCCESS';
export const ACTION_LOGOUT__FAIL = 'ACTION_LOGOUT__FAIL';

export const ACTION_LOGIN_BY_CREDENTIALS__REQUEST = 'ACTION_LOGIN_BY_CREDENTIALS__REQUEST';
export const ACTION_LOGIN_BY_CREDENTIALS__SUCCESS = 'ACTION_LOGIN_BY_CREDENTIALS__SUCCESS';
export const ACTION_LOGIN_BY_CREDENTIALS__FAIL = 'ACTION_LOGIN_BY_CREDENTIALS__FAIL';

export const ACTION_LOGIN_BY_SOCIAL__REQUEST = 'ACTION_LOGIN_BY_SOCIAL__REQUEST';
export const ACTION_LOGIN_BY_SOCIAL__SUCCESS = 'ACTION_LOGIN_BY_SOCIAL__SUCCESS';
export const ACTION_LOGIN_BY_SOCIAL__FAIL = 'ACTION_LOGIN_BY_SOCIAL__FAIL';

export const ACTION_LOGIN_BY_AUTH_TOKEN__REQUEST = 'ACTION_LOGIN_BY_AUTH_TOKEN__REQUEST';
export const ACTION_LOGIN_BY_AUTH_TOKEN__SUCCESS = 'ACTION_LOGIN_BY_AUTH_TOKEN__SUCCESS';
export const ACTION_LOGIN_BY_AUTH_TOKEN__FAIL = 'ACTION_LOGIN_BY_AUTH_TOKEN__FAIL';

export const ACTION_SET_LOGIN_INFO = 'ACTION_SET_LOGIN_INFO';

export const ACTION_LOAD_LOGIN_INFO__REQUEST = 'ACTION_LOAD_LOGIN_INFO__REQUEST';
export const ACTION_LOAD_LOGIN_INFO__SUCCESS = 'ACTION_LOAD_LOGIN_INFO__SUCCESS';
export const ACTION_LOAD_LOGIN_INFO__FAIL = 'ACTION_LOAD_LOGIN_INFO__FAIL';

export const ACTION_UPDATE_LOGGED_USER = 'ACTION_UPDATE_LOGGED_USER';

export const ACTION_RESET_DEPOSIT_REMIND__REQUEST = 'ACTION_RESET_DEPOSIT_REMIND__REQUEST';
export const ACTION_RESET_DEPOSIT_REMIND__SUCCESS = 'ACTION_RESET_DEPOSIT_REMIND__SUCCESS';
export const ACTION_RESET_DEPOSIT_REMIND__FAIL = 'ACTION_RESET_DEPOSIT_REMIND__FAIL';

export const ACTION_UNBAN__REQUEST = 'ACTION_UNBAN__REQUEST';
export const ACTION_UNBAN__SUCCESS = 'ACTION_UNBAN__SUCCESS';
export const ACTION_UNBAN__FAIL = 'ACTION_UNBAN__FAIL';

export const ACTION_VISIT_PAGE__REQUEST = 'ACTION_VISIT_PAGE__REQUEST';
export const ACTION_VISIT_PAGE__SUCCESS = 'ACTION_VISIT_PAGE__SUCCESS';
export const ACTION_VISIT_PAGE__FAIL = 'ACTION_VISIT_PAGE__FAIL';

export const ACTION_DELETE_NOTIFICATION__REQUEST = 'ACTION_DELETE_NOTIFICATION__REQUEST';
export const ACTION_DELETE_NOTIFICATION__SUCCESS = 'ACTION_DELETE_NOTIFICATION__SUCCESS';
export const ACTION_DELETE_NOTIFICATION__FAIL = 'ACTION_DELETE_NOTIFICATION__FAIL';

export const ACTION_LOAD_ZENDESK_LOGIN_LINK__REQUEST = 'ACTION_LOAD_ZENDESK_LOGIN_LINK__REQUEST';
export const ACTION_LOAD_ZENDESK_LOGIN_LINK__SUCCESS = 'ACTION_LOAD_ZENDESK_LOGIN_LINK__SUCCESS';
export const ACTION_LOAD_ZENDESK_LOGIN_LINK__FAIL = 'ACTION_LOAD_ZENDESK_LOGIN_LINK__FAIL';

export const ACTION_ACCEPT_PRIZE__REQUEST = 'ACTION_ACCEPT_PRIZE__REQUEST';
export const ACTION_ACCEPT_PRIZE__SUCCESS = 'ACTION_ACCEPT_PRIZE__SUCCESS';
export const ACTION_ACCEPT_PRIZE__FAIL = 'ACTION_ACCEPT_PRIZE__FAIL';

export const ACTION_LOAD_SPIN_PRICE__REQUEST = 'ACTION_LOAD_SPIN_PRICE__REQUEST';
export const ACTION_LOAD_SPIN_PRICE__SUCCESS = 'ACTION_LOAD_SPIN_PRICE__SUCCESS';
export const ACTION_LOAD_SPIN_PRICE__FAIL = 'ACTION_LOAD_SPIN_PRICE__FAIL';

export const ACTION_SPIN_ROULETTE__REQUEST = 'ACTION_SPIN_ROULETTE__REQUEST';
export const ACTION_SPIN_ROULETTE__SUCCESS = 'ACTION_SPIN_ROULETTE__SUCCESS';
export const ACTION_SPIN_ROULETTE__FAIL = 'ACTION_SPIN_ROULETTE__FAIL';

export const ACTION_SET_IS_ROULETTE_SPIN_CONFIRMED = 'ACTION_SET_IS_ROULETTE_SPIN_CONFIRMED';
export const ACTION_SET_MODEL_ID = 'ACTION_SET_MODEL_ID';

export const ACTION_LOGIN_AS_MODEL__REQUEST = 'ACTION_LOGIN_AS_MODEL__REQUEST';
export const ACTION_LOGIN_AS_MODEL__SUCCESS = 'ACTION_LOGIN_AS_MODEL__SUCCESS';
export const ACTION_LOGIN_AS_MODEL__FAIL = 'ACTION_LOGIN_AS_MODEL__FAIL';

export const ACTION_RETURN_TO_MASTER__REQUEST = 'ACTION_RETURN_TO_MASTER__REQUEST';
export const ACTION_RETURN_TO_MASTER__SUCCESS = 'ACTION_RETURN_TO_MASTER__SUCCESS';
export const ACTION_RETURN_TO_MASTER__FAIL = 'ACTION_RETURN_TO_MASTER__FAIL';
