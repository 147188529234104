import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getIsPrivateContentForbidden } from '../../../initSettings/selectors';
import { getLoadErostoriesError } from '../../selectors/erostory';
import { getLoadPrivatePhotosError } from '../../selectors/privatePhoto';
import { getLoadUnderwearError } from '../../selectors/underwear';
import { getLoadVideosError } from '../../selectors/video';

import { actionLoadPurchasedErostoriesRequest } from '../../actions/erostory';
import { actionLoadPurchasedPrivatePhotosRequest } from '../../actions/privatePhoto';
import { actionLoadPurchasedUnderwearRequest } from '../../actions/underwear';
import { actionLoadPurchasedVideosRequest } from '../../actions/video';


export default ({ getState, dispatch }) => (
  function loadPurchasedItemsHandler(nextState, successCallback, errorCallback) {
    const isPrivateContentAllowed = !getIsPrivateContentForbidden(getState());

    Promise.all([
      isPrivateContentAllowed && dispatch(actionLoadPurchasedVideosRequest()),
      isPrivateContentAllowed && dispatch(actionLoadPurchasedPrivatePhotosRequest()),
      dispatch(actionLoadPurchasedErostoriesRequest()),
      dispatch(actionLoadPurchasedUnderwearRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError } of [
          getLoadVideosError(getState()),
          getLoadPrivatePhotosError(getState()),
          getLoadErostoriesError(getState()),
          getLoadUnderwearError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadPurchasedItemsHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
