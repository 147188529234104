import { getStudiosUrl } from '../../app/utils/url';

import {
  ACTION_RETURN_TO_MASTER__REQUEST,
  ACTION_RETURN_TO_MASTER__SUCCESS,
  ACTION_RETURN_TO_MASTER__FAIL,
} from '../actionTypes';

import { STUDIO_TAB__MODELS } from '../../studio/constants/tabs';

import { getCurrentLangForUrl } from '../../core/i18n/selectors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionSetAuthData } from './auth';


const actionReturnToMasterSuccess = () => ({
  type: ACTION_RETURN_TO_MASTER__SUCCESS,
});

const actionReturnToMasterFail = error => ({
  type: ACTION_RETURN_TO_MASTER__FAIL,
  payload: { error },
});

export const actionReturnToMasterRequest = () => (
  (dispatch, getState, { apiClientHelper, history: { push } }) => {
    dispatch({
      type: ACTION_RETURN_TO_MASTER__REQUEST,
    });

    return apiClientHelper.del(
      'login/as'
    ).then(
      auth => {
        dispatch(actionSetAuthData(auth));

        dispatch(actionReturnToMasterSuccess());

        setTimeout(() => {
          push(getStudiosUrl(getCurrentLangForUrl(getState()), STUDIO_TAB__MODELS));
        });
      },
      error => {
        dispatch(actionReturnToMasterFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
