import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles/index.css.json';


const propTypes = {
  showAmEx: PropTypes.bool,
  className: PropTypes.string.isRequired,
};

const CardLogos = ({ showAmEx = false, className }) => (
  <div className={classnames(styles.cardLogos, className)}>
    <div className={classnames(styles.cardLogo, styles.visa)} />
    <div className={classnames(styles.cardLogo, styles.mastercard)} />
    {showAmEx && <div className={classnames(styles.cardLogo, styles.americanexpress)} />}
    <div className={classnames(styles.cardLogo, styles.ds3)} />
  </div>
);

CardLogos.propTypes = propTypes;

export default CardLogos;
