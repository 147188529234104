import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import BlueCheck from '../BlueCheck';

import styles from './styles/index.css.json';


const propTypes = {
  content: PropTypes.element.isRequired,
  blueCheckProps: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const BlueCheckContainer = ({ content, blueCheckProps, className = null }) => (
  <span className={classnames(styles.blueCheckContainer, className)}>
    {content}
    {React.createElement(BlueCheck, { ...blueCheckProps, className: styles.blueCheck })}
  </span>
);

BlueCheckContainer.propTypes = propTypes;

export default BlueCheckContainer;
