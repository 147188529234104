import { getUrlWithModelId } from '../../studio/utils';

import {
  ACTION_LOAD_STEP__REQUEST,
  ACTION_LOAD_STEP__SUCCESS,
  ACTION_LOAD_STEP__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadStepSuccess = step => ({
  type: ACTION_LOAD_STEP__SUCCESS,
  payload: { step },
});

const actionLoadStepFail = error => ({
  type: ACTION_LOAD_STEP__FAIL,
  payload: { error },
});

export const actionLoadStepRequest = () => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    dispatch({
      type: ACTION_LOAD_STEP__REQUEST,
    });

    return apiClientHelper.get(
      getUrlWithModelId('register/step_info', location)
    ).then(
      step => {
        dispatch(actionLoadStepSuccess(step));
      },
      error => {
        dispatch(actionLoadStepFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
