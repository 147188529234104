import {
  ACTION_RETURN_TO_MASTER__REQUEST,
  ACTION_RETURN_TO_MASTER__SUCCESS,
  ACTION_RETURN_TO_MASTER__FAIL,
} from '../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_RETURN_TO_MASTER__REQUEST:
    case ACTION_RETURN_TO_MASTER__SUCCESS:
      return null;

    case ACTION_RETURN_TO_MASTER__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
