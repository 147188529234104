import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';
import convertApiErrorsToFinalFormErrors from '../../../core/api/utils/convertApiErrorsToFinalFormErrors';
import { getFilter } from '../../../core/loadableItems/utils';
import { getPhotosUrl } from '../../../app/utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import { CATEGORY_QUERY_PARAM } from '../../constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getLoadLandingPageError } from '../../../landingPage/selectors/errors';
import {
  getPrivatePhotoCategories,
  getLoadPrivatePhotosError,
  getLoadPrivatePhotoCategoriesError,
} from '../../selectors/privatePhoto';

import { actionLoadLandingPageRequest } from '../../../landingPage/actions/loadLandingPage';
import {
  actionLoadPrivatePhotosRequest,
  actionLoadPrivatePhotoCategoriesRequest,
} from '../../actions/privatePhoto';


export default ({ getState, dispatch }) => (
  function loadPrivatePhotosHandler(nextState, successCallback, errorCallback) {
    const selectedCategory = getFilter(nextState.location)[CATEGORY_QUERY_PARAM];
    const photosUrl = getPhotosUrl(getCurrentLangForUrl(getState()));

    Promise.all([
      dispatch(actionLoadLandingPageRequest()),
      dispatch(actionLoadPrivatePhotosRequest()),
      dispatch(actionLoadPrivatePhotoCategoriesRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const loadLandingPageError = getLoadLandingPageError(getState());
        if (maybeHandleCriticalError(
          nextState.location, loadLandingPageError?.globalError, errorCallback
        )) {
          return;
        }

        const loadPrivatePhotosError = getLoadPrivatePhotosError(getState());
        if (loadPrivatePhotosError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadPrivatePhotosError.globalError, errorCallback
          )) {
            const errors = convertApiErrorsToFinalFormErrors(loadPrivatePhotosError);
            errorCallback(errors.orderBy ? photosUrl : INTERNAL_ERROR_URI);
          }
          return;
        }

        const loadPrivatePhotoCategoriesError = getLoadPrivatePhotoCategoriesError(getState());
        if (loadPrivatePhotoCategoriesError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadPrivatePhotoCategoriesError.globalError, errorCallback
          )) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        if (selectedCategory && getPrivatePhotoCategories(getState()).filter(
          category => category.id === parseInt(selectedCategory, 10)
        ).length === 0) {
          errorCallback(photosUrl);
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadPrivatePhotosHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
