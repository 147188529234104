import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException, url } from 'react-redux-app/lib/modules/routing/utils';
import { getIndexUrl, getSystemErrorUrl } from '../../../app/utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import { FORBIDDEN } from '../../../core/api/constants/errors';
import { REGISTRATION_WRONG_SITE } from '../../constants/errors';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getLoadPartialInitSettingsError } from '../../../initSettings/selectors/errors';
import { getLoadStepError } from '../../selectors/errors';

import { actionLoadStepSettingsRequest } from '../../../initSettings/actions/loadPartialInitSettings';
import { actionLoadStepRequest } from '../../actions/loadStep';


export default ({ getState, dispatch }) => (
  function loadStepHandler(nextState, successCallback, errorCallback) {
    Promise.all([
      dispatch(actionLoadStepRequest()),
      dispatch(actionLoadStepSettingsRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const loadStepError = getLoadStepError(getState());
        if (loadStepError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadStepError.globalError, errorCallback
          )) {
            if (loadStepError.globalError.code === FORBIDDEN) {
              errorCallback(getIndexUrl(getCurrentLangForUrl(getState())));
            } else if (loadStepError.globalError.code === REGISTRATION_WRONG_SITE) {
              errorCallback(url.appendQueryParams(
                getSystemErrorUrl(getCurrentLangForUrl(getState())),
                { message: 'error.finish-registration-at-your-site' }
              ));
            } else {
              errorCallback(INTERNAL_ERROR_URI);
            }
          }
          return;
        }

        const loadPartialInitSettingsError = getLoadPartialInitSettingsError(getState());
        if (loadPartialInitSettingsError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadPartialInitSettingsError.globalError, errorCallback
          )) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadStepHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
