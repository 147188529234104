import useIsStudio from './useIsStudio';
import useIsModelHub from './useIsModelHub';


export default () => {
  const isStudio = useIsStudio();
  const isModelHub = useIsModelHub();

  return isStudio || isModelHub;
};
