import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';
import { getNotFoundUrl } from '../../../app/utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getIsForBoys } from '../../../initSettings/selectors';
import { getLoadAwardsError } from '../../selectors/errors';

import { actionLoadAwardsRequest } from '../../actions';


export default ({ getState, dispatch }) => (
  function loadAwardsHandler(nextState, successCallback, errorCallback) {
    if (getIsForBoys(getState())) {
      errorCallback(getNotFoundUrl(getCurrentLangForUrl(getState())));
      return;
    }

    dispatch(
      actionLoadAwardsRequest()
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = getLoadAwardsError(getState());
        if (error) {
          if (!maybeHandleCriticalError(nextState.location, error.globalError, errorCallback)) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadAwardsHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
