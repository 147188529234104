import { getGridFilter } from '../utils/studio';

import { ACTION_ADD_STUDIOS } from '../actionTypes';

import { STUDIOS_GRID_NAME } from '../constants/studio';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddStudios = studios => ({
  type: ACTION_ADD_STUDIOS,
  payload: { studios },
});

export const actionLoadStudiosRequest = getActionLoadGridDataRequest(
  STUDIOS_GRID_NAME, getGridFilter, 'studio/list', actionAddStudios
);
