import {
  takeLatest,
  put,
  putResolve,
  select,
} from 'redux-saga/effects';

import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalErrorWithHistory } from 'react-redux-app/lib/modules/api/utils';

import {
  ACTION_CREATE_STUDIO__SUCCESS,
  ACTION_DELETE_STUDIO__SUCCESS,
  ACTION_UPDATE_STUDIO__SUCCESS,
} from '../actionTypes';

import { getLoadStudiosError } from '../selectors/errors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionLoadStudiosRequest } from '../actions/loadStudios';


function* reloadMasterStudios(history, action) {
  try {
    console.consoleLog('reload MasterStudios by action:', action);

    yield putResolve(actionLoadStudiosRequest());

    const error = yield select(getLoadStudiosError);
    if (error) {
      maybeHandleCriticalErrorWithHistory(history, error.globalError);
    }
  } catch (e) {
    yield put(actionAddException(e));
  }
}

export default function* saga(history) {
  yield takeLatest([
    ACTION_CREATE_STUDIO__SUCCESS,
    ACTION_DELETE_STUDIO__SUCCESS,
    ACTION_UPDATE_STUDIO__SUCCESS,
  ], reloadMasterStudios, history);
}
