import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'studio.errors');
export const getCreateStudioError = state => (
  _get(errorsSelector(state), 'createStudioError')
);
export const getDeleteStudioError = state => (
  _get(errorsSelector(state), 'deleteStudioError')
);
export const getUpdateStudioError = state => (
  _get(errorsSelector(state), 'updateStudioError')
);
export const getUpdateModelStatusError = state => (
  _get(errorsSelector(state), 'updateModelStatusError')
);
export const getLoadMasterModelError = state => (
  _get(errorsSelector(state), 'loadMasterModelError')
);
export const getLoadStudiosError = state => (
  _get(errorsSelector(state), 'loadStudiosError')
);
export const getAssignModelToStudioError = state => (
  _get(errorsSelector(state), 'assignModelToStudioError')
);
