import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getIsPrivateContentForbidden } from '../../../initSettings/selectors';
import {
  getLoadLatestPublicPhotosError,
  getLoadLatestPrivatePhotosError,
  getLoadLatestVideosError,
  getLoadLatestErostoriesError,
  getLoadLatestUnderwearError,
} from '../../selectors';

import { actionLoadLatestErostoriesRequest } from '../../actions/loadLatestErostories';
import { actionLoadLatestPrivatePhotosRequest } from '../../actions/loadLatestPrivatePhotos';
import { actionLoadLatestPublicPhotosRequest } from '../../actions/loadLatestPublicPhotos';
import { actionLoadLatestUnderwearRequest } from '../../actions/loadLatestUnderwear';
import { actionLoadLatestVideosRequest } from '../../actions/loadLatestVideos';


export default ({ getState, dispatch }) => (
  function loadLatestItemsHandler(nextState, successCallback, errorCallback) {
    const isPrivateContentAllowed = !getIsPrivateContentForbidden(getState());

    Promise.all([
      dispatch(actionLoadLatestPublicPhotosRequest()),
      isPrivateContentAllowed && dispatch(actionLoadLatestPrivatePhotosRequest()),
      isPrivateContentAllowed && dispatch(actionLoadLatestVideosRequest()),
      dispatch(actionLoadLatestErostoriesRequest()),
      dispatch(actionLoadLatestUnderwearRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError } of [
          getLoadLatestPublicPhotosError(getState()),
          getLoadLatestPrivatePhotosError(getState()),
          getLoadLatestVideosError(getState()),
          getLoadLatestErostoriesError(getState()),
          getLoadLatestUnderwearError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadLatestItemsHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
