import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_ADD_MODELS,
  ACTION_ADD_STUDIOS,
  ACTION_DELETE_STUDIO__REQUEST,
  ACTION_DELETE_STUDIO__SUCCESS,
  ACTION_DELETE_STUDIO__FAIL,
  ACTION_UPDATE_MODEL_STATUS__REQUEST,
  ACTION_UPDATE_MODEL_STATUS__SUCCESS,
  ACTION_UPDATE_MODEL_STATUS__FAIL,
  ACTION_LOAD_MASTER_MODEL__REQUEST,
  ACTION_LOAD_MASTER_MODEL__SUCCESS,
  ACTION_LOAD_STUDIOS__SUCCESS,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_STUDIOS:
      return { ...state, ...action.payload.studios };

    case ACTION_DELETE_STUDIO__SUCCESS:
      return _omit(state, action.payload.studioId);

    default:
      return state;
  }
};

const modelsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_MODELS:
      return { ...state, ...action.payload.models };

    case ACTION_UPDATE_MODEL_STATUS__SUCCESS:
      return _omit(state, action.payload.modelId);

    default:
      return state;
  }
};

const isStudioBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_STUDIO__REQUEST:
      return { ...state, [action.payload.studioId]: true };

    case ACTION_DELETE_STUDIO__SUCCESS:
    case ACTION_DELETE_STUDIO__FAIL:
      return _omit(state, action.payload.studioId);

    default:
      return state;
  }
};


const isModelStatusBeingChanged = (state = {}, action) => {
  switch (action.type) {
    case ACTION_UPDATE_MODEL_STATUS__REQUEST:
      return { ...state, [action.payload.modelId]: true };

    case ACTION_UPDATE_MODEL_STATUS__SUCCESS:
    case ACTION_UPDATE_MODEL_STATUS__FAIL:
      return _omit(state, action.payload.modelId);

    default:
      return state;
  }
};

const masterModel = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_MASTER_MODEL__REQUEST:
      return null;

    case ACTION_LOAD_MASTER_MODEL__SUCCESS:
      return action.payload.masterModel;

    default:
      return state;
  }
};

const studios = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_STUDIOS__SUCCESS:
      return action.payload.studios;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  modelsById,
  isStudioBeingDeleted,
  isModelStatusBeingChanged,
  masterModel,
  studios,
  errors,
});
