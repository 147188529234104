import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getLoadPartialInitSettingsError } from '../../../initSettings/selectors/errors';
import { getLoadSitemapError } from '../../selectors/errors';

import { actionLoadSitemapSettingsRequest } from '../../../initSettings/actions/loadPartialInitSettings';
import { actionLoadSitemapRequest } from '../../actions/loadSitemap';


export default ({ getState, dispatch }) => (
  function loadSitemapHandler(nextState, successCallback, errorCallback) {
    Promise.all([
      dispatch(actionLoadSitemapRequest()),
      dispatch(actionLoadSitemapSettingsRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError } of [
          getLoadSitemapError(getState()),
          getLoadPartialInitSettingsError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadSitemapHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
