import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { getUri, handleOnEnterException, url } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import { DISCOUNT_CODE_COOKIE } from '../../constants';

import { getDepositDiscountCode } from '../../selectors';
import { getLoadDepositSettingsError, getLoadRewardSettingsError } from '../../selectors/errors';

import { actionClearGateResult } from '../../actions/buyCreditsLocally';
import { actionLoadDepositSettingsRequest } from '../../actions/loadDepositSettings';
import { actionLoadRewardSettingsRequest } from '../../actions/loadRewardSettings';
import { actionDropDiscountCode } from '../../actions/setDiscountCode';


export default ({ getState, dispatch }) => (
  function loadDepositSettingsHandler(nextState, successCallback, errorCallback) {
    const discountCode = getDepositDiscountCode(getState());

    Promise.all([
      dispatch(actionClearGateResult()),
      dispatch(actionLoadDepositSettingsRequest(discountCode)),
      dispatch(actionLoadRewardSettingsRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const loadDepositSettingsError = getLoadDepositSettingsError(getState());
        if (loadDepositSettingsError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadDepositSettingsError.globalError, errorCallback
          )) {
            if (discountCode) {
              dispatch(actionDropDiscountCode());
              errorCallback(
                url.removeQueryParams(getUri(nextState.location), DISCOUNT_CODE_COOKIE)
              );
            } else {
              errorCallback(INTERNAL_ERROR_URI);
            }
          }
          return;
        }

        const loadRewardSettingsError = getLoadRewardSettingsError(getState());
        if (loadRewardSettingsError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadRewardSettingsError.globalError, errorCallback
          )) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadDepositSettingsHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
