import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getLoadPartialInitSettingsError } from '../../selectors/errors';


export default actionLoadPartialInitSettingsRequest => ({ getState, dispatch }) => (
  function loadPartialInitSettingsHandler(nextState, successCallback, errorCallback) {
    dispatch(
      actionLoadPartialInitSettingsRequest()
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = getLoadPartialInitSettingsError(getState());
        if (error) {
          if (!maybeHandleCriticalError(nextState.location, error.globalError, errorCallback)) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError(
        `loadPartialInitSettingsHandler(${actionLoadPartialInitSettingsRequest.name}) failed`,
        error
      );
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
