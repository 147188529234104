import { all } from 'redux-saga/effects';

import config from '../../../../../../config';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import getWatchExceptions from 'react-redux-app/lib/modules/exception/saga';
import pushNotification from '../../core/pushNotification/sagas/pushNotification';
import geoFiltersGrid from '../../account/sagas/grids/geoFiltersGrid';
import whiteListGrid from '../../account/sagas/grids/whiteListGrid';
import appointmentsGrid from '../../appointment/sagas/grids/appointmentsGrid';
import authData from '../../auth/sagas/authData';
import paidRouletteModal from '../../auth/sagas/modals/paidRouletteModal';
import revenueConversionsGrid from '../../balance/sagas/grids/revenueConversionsGrid';
import bannedUsersGrid from '../../bannedUser/sagas/grids/bannedUsersGrid';
import blackListGrid from '../../blackList/sagas/grids/blackListGrid';
import buyPostModal from '../../blog/sagas/modals/buyPostModal';
import subscribeModal from '../../blog/sagas/modals/subscribeModal';
import depositsGrid from '../../deposit/sagas/grids/depositsGrid';
import rewardRequestsGrid from '../../deposit/sagas/grids/rewardRequestsGrid';
import dialogsGrid from '../../dialog/sagas/dialogsGrid';
import interviewFormsGrid from '../../interview/sagas/grids/interviewFormsGrid';
import interviewRequestsGrid from '../../interview/sagas/grids/interviewRequestsGrid';
import mediaViewer from '../../mediaViewer/sagas/mediaViewer';
import privatesGrid from '../../ownSaleItem/sagas/grids/privatesGrid';
import partnersGrid from '../../partner/sagas/grids/partnersGrid';
import payoutsGrid from '../../revenue/sagas/grids/payoutsGrid';
import revenueSettings from '../../revenue/sagas/revenueSettings';
import buySaleItemModal from '../../saleItem/sagas/modals/buySaleItemModal';
import buySimpleSaleItemModal from '../../saleItem/sagas/modals/buySimpleSaleItemModal';
import studios from '../../studio/sagas/studios';
import modelsGrid from '../../studio/sagas/grids/modelsGrid';
import studiosGrid from '../../studio/sagas/grids/studiosGrid';
import underwearRequestsGrid from '../../underwear/sagas/grids/underwearRequestsGrid';


export default function* saga(history) {
  yield all([
    getWatchExceptions(INTERNAL_ERROR_URI, config.isDevToolsEnabled)(),
    pushNotification(),
    geoFiltersGrid(history),
    whiteListGrid(history),
    appointmentsGrid(history),
    authData(history),
    paidRouletteModal(),
    revenueConversionsGrid(history),
    bannedUsersGrid(history),
    blackListGrid(history),
    buyPostModal(),
    subscribeModal(),
    depositsGrid(history),
    rewardRequestsGrid(history),
    dialogsGrid(history),
    interviewFormsGrid(history),
    interviewRequestsGrid(history),
    mediaViewer(history),
    privatesGrid(history),
    partnersGrid(history),
    payoutsGrid(history),
    revenueSettings(history),
    buySaleItemModal(),
    buySimpleSaleItemModal(),
    studios(history),
    modelsGrid(history),
    studiosGrid(history),
    underwearRequestsGrid(history),
  ]);
}
