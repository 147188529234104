import {
  ACTION_CREATE_STUDIO__REQUEST,
  ACTION_CREATE_STUDIO__SUCCESS,
  ACTION_CREATE_STUDIO__FAIL,
} from '../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_CREATE_STUDIO__REQUEST:
    case ACTION_CREATE_STUDIO__SUCCESS:
      return null;

    case ACTION_CREATE_STUDIO__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
