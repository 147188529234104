import {
  ACTION_UPDATE_MODEL_STATUS__REQUEST,
  ACTION_UPDATE_MODEL_STATUS__SUCCESS,
  ACTION_UPDATE_MODEL_STATUS__FAIL,
  ACTION_ASSIGN_MODEL_TO_STUDIO__REQUEST,
  ACTION_ASSIGN_MODEL_TO_STUDIO__SUCCESS,
  ACTION_ASSIGN_MODEL_TO_STUDIO__FAIL,
} from '../../actionTypes';

import { MODELS_GRID_NAME } from '../../constants/model';

import { actionLoadModelsRequest } from '../../actions/modelsGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  MODELS_GRID_NAME,
  actionLoadModelsRequest,
  [ACTION_UPDATE_MODEL_STATUS__REQUEST, ACTION_ASSIGN_MODEL_TO_STUDIO__REQUEST],
  [ACTION_UPDATE_MODEL_STATUS__FAIL, ACTION_ASSIGN_MODEL_TO_STUDIO__FAIL],
  [ACTION_UPDATE_MODEL_STATUS__SUCCESS, ACTION_ASSIGN_MODEL_TO_STUDIO__SUCCESS]
);
