import {
  ACTION_LOAD_STUDIOS__REQUEST,
  ACTION_LOAD_STUDIOS__SUCCESS,
  ACTION_LOAD_STUDIOS__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadStudiosSuccess = studios => ({
  type: ACTION_LOAD_STUDIOS__SUCCESS,
  payload: { studios },
});

const actionLoadStudiosFail = error => ({
  type: ACTION_LOAD_STUDIOS__FAIL,
  payload: { error },
});

export const actionLoadStudiosRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_STUDIOS__REQUEST,
    });

    return apiClientHelper.get(
      'studio/init'
    ).then(
      studios => {
        dispatch(actionLoadStudiosSuccess(studios));
      },
      error => {
        dispatch(actionLoadStudiosFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
