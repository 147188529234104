import { getGridFilter } from '../utils/model';

import { ACTION_ADD_MODELS } from '../actionTypes';

import { MODELS_GRID_NAME } from '../constants/model';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddModels = models => ({
  type: ACTION_ADD_MODELS,
  payload: { models },
});

export const actionLoadModelsRequest = getActionLoadGridDataRequest(
  MODELS_GRID_NAME, getGridFilter, 'studio/model/list', actionAddModels
);
