export const PAYOUT_METHOD__BANK = 'wire';
export const PAYOUT_METHOD__XCARD = 'xcard';
export const PAYOUT_METHOD__REVOLUT = 'revolut';
// Not implemented
export const PAYOUT_METHOD__CREDITCARD = 'creditcard';
export const PAYOUT_METHOD__EPASSPORTE = 'epassporte';
export const PAYOUT_METHOD__WCCHECK = 'wccheck';
export const PAYOUT_METHOD__WCWIRE = 'wcwire';
export const PAYOUT_METHOD__PAYONEER = 'payoneer';

export const payoutMethods = [
  PAYOUT_METHOD__BANK,
  PAYOUT_METHOD__XCARD,
  PAYOUT_METHOD__REVOLUT,
  PAYOUT_METHOD__CREDITCARD,
  PAYOUT_METHOD__EPASSPORTE,
  PAYOUT_METHOD__WCCHECK,
  PAYOUT_METHOD__WCWIRE,
  PAYOUT_METHOD__PAYONEER,
];

export const payoutMethodLangKeys = {
  [PAYOUT_METHOD__BANK]: 'msg.payout-method.wire',
  [PAYOUT_METHOD__XCARD]: 'msg.payout-method.xcard',
  [PAYOUT_METHOD__REVOLUT]: 'msg.payout-method.revolut',
  [PAYOUT_METHOD__CREDITCARD]: 'msg.payout-method.creditcard',
  [PAYOUT_METHOD__EPASSPORTE]: 'msg.payout-method.epassporte',
  [PAYOUT_METHOD__WCCHECK]: 'msg.payout-method.wccheck',
  [PAYOUT_METHOD__WCWIRE]: 'msg.payout-method.wcwire',
  [PAYOUT_METHOD__PAYONEER]: 'msg.payout-method.payoneer',
};

export const PAYOUT_STATUS__PENDING = 'pending';
export const PAYOUT_STATUS__APPROVED = 'approved';
export const PAYOUT_STATUS__DECLINED = 'declined';
export const PAYOUT_STATUS__CANCELED = 'canceled';

export const payoutStatuses = [
  PAYOUT_STATUS__PENDING,
  PAYOUT_STATUS__APPROVED,
  PAYOUT_STATUS__DECLINED,
  PAYOUT_STATUS__CANCELED,
];

export const payoutStatusLangKeys = {
  [PAYOUT_STATUS__PENDING]: 'msg.status.pending',
  [PAYOUT_STATUS__APPROVED]: 'msg.status.approved',
  [PAYOUT_STATUS__DECLINED]: 'msg.status.declined',
  [PAYOUT_STATUS__CANCELED]: 'msg.status.canceled',
};

export const COMPLAINT_STATUS__NEW = 'new';
export const COMPLAINT_STATUS__IN_PROGRESS = 'inprogress';
export const COMPLAINT_STATUS__CLOSED = 'closed';

export const complaintStatuses = [
  COMPLAINT_STATUS__NEW,
  COMPLAINT_STATUS__IN_PROGRESS,
  COMPLAINT_STATUS__CLOSED,
];

export const complaintStatusLangKeys = {
  [COMPLAINT_STATUS__NEW]: 'msg.status.new',
  [COMPLAINT_STATUS__IN_PROGRESS]: 'msg.status.in-progress',
  [COMPLAINT_STATUS__CLOSED]: 'msg.status.closed',
};

export const BANK_ACCOUNT_STATUS__PENDING = 'pending';
export const BANK_ACCOUNT_STATUS__APPROVED = 'approved';
export const BANK_ACCOUNT_STATUS__CANCELED = 'canceled';
export const BANK_ACCOUNT_STATUS__REJECTED = 'rejected';
export const BANK_ACCOUNT_STATUS__ARCHIVED = 'archived';

export const LATEST_SALES_GRID_NAME = 'LatestSalesGrid';
