import _range from 'lodash/range';

import { multiSelect } from 'react-redux-app/lib/modules/form/utils';
import { url } from 'react-redux-app/lib/modules/routing/utils';

import { MODEL_QUERY_PARAM } from '../constants';


export const getUrlWithModelId = (path, location) => {
  const modelId = url.parseQuery(location.search)[MODEL_QUERY_PARAM];

  return `${path}${modelId ? `/${modelId}` : ''}`;
};

export const getPercentOptions = (isEmptyRequired = false) => (
  multiSelect.addEmptyOption(
    _range(1, 101).map(p => multiSelect.createOption(`${p}%`, p)),
    isEmptyRequired
  )
);
