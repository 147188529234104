import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getLoadInterviewError } from '../../selectors/interview';

import { actionLoadInterviewRequest } from '../../actions/loadInterview';


export default ({ getState, dispatch }) => (
  function loadInterviewHandler(nextState, successCallback, errorCallback) {
    const { id } = nextState.params;

    dispatch(
      actionLoadInterviewRequest(id)
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = getLoadInterviewError(getState());
        if (error) {
          if (!maybeHandleCriticalError(nextState.location, error.globalError, errorCallback)) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadInterviewHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
