export const ACTION_LOAD_REVENUE_SETTINGS__REQUEST = 'ACTION_LOAD_REVENUE_SETTINGS__REQUEST';
export const ACTION_LOAD_REVENUE_SETTINGS__SUCCESS = 'ACTION_LOAD_REVENUE_SETTINGS__SUCCESS';
export const ACTION_LOAD_REVENUE_SETTINGS__FAIL = 'ACTION_LOAD_REVENUE_SETTINGS__FAIL';

export const ACTION_REQUEST_PAYOUT__REQUEST = 'ACTION_REQUEST_PAYOUT__REQUEST';
export const ACTION_REQUEST_PAYOUT__SUCCESS = 'ACTION_REQUEST_PAYOUT__SUCCESS';
export const ACTION_REQUEST_PAYOUT__FAIL = 'ACTION_REQUEST_PAYOUT__FAIL';

export const ACTION_UPDATE_BANK_ACCOUNT_DETAILS__REQUEST = 'ACTION_UPDATE_BANK_ACCOUNT_DETAILS__REQUEST';
export const ACTION_UPDATE_BANK_ACCOUNT_DETAILS__SUCCESS = 'ACTION_UPDATE_BANK_ACCOUNT_DETAILS__SUCCESS';
export const ACTION_UPDATE_BANK_ACCOUNT_DETAILS__FAIL = 'ACTION_UPDATE_BANK_ACCOUNT_DETAILS__FAIL';

export const ACTION_ORDER_XCARD__REQUEST = 'ACTION_ORDER_XCARD__REQUEST';
export const ACTION_ORDER_XCARD__SUCCESS = 'ACTION_ORDER_XCARD__SUCCESS';
export const ACTION_ORDER_XCARD__FAIL = 'ACTION_ORDER_XCARD__FAIL';

export const ACTION_CONNECT_REVOLUT__REQUEST = 'ACTION_CONNECT_REVOLUT__REQUEST';
export const ACTION_CONNECT_REVOLUT__SUCCESS = 'ACTION_CONNECT_REVOLUT__SUCCESS';
export const ACTION_CONNECT_REVOLUT__FAIL = 'ACTION_CONNECT_REVOLUT__FAIL';

export const ACTION_SUBMIT_FISCAL_NUMBER__REQUEST = 'ACTION_SUBMIT_FISCAL_NUMBER__REQUEST';
export const ACTION_SUBMIT_FISCAL_NUMBER__SUCCESS = 'ACTION_SUBMIT_FISCAL_NUMBER__SUCCESS';
export const ACTION_SUBMIT_FISCAL_NUMBER__FAIL = 'ACTION_SUBMIT_FISCAL_NUMBER__FAIL';

export const ACTION_ADD_PAYOUTS = 'ACTION_ADD_PAYOUTS';

export const ACTION_CANCEL_PAYOUT_REQUEST__REQUEST = 'ACTION_CANCEL_PAYOUT_REQUEST__REQUEST';
export const ACTION_CANCEL_PAYOUT_REQUEST__SUCCESS = 'ACTION_CANCEL_PAYOUT_REQUEST__SUCCESS';
export const ACTION_CANCEL_PAYOUT_REQUEST__FAIL = 'ACTION_CANCEL_PAYOUT_REQUEST__FAIL';

export const ACTION_DOWNLOAD_PAYOUT_INVOICE__REQUEST = 'ACTION_DOWNLOAD_PAYOUT_INVOICE__REQUEST';
export const ACTION_DOWNLOAD_PAYOUT_INVOICE__SUCCESS = 'ACTION_DOWNLOAD_PAYOUT_INVOICE__SUCCESS';
export const ACTION_DOWNLOAD_PAYOUT_INVOICE__FAIL = 'ACTION_DOWNLOAD_PAYOUT_INVOICE__FAIL';

export const ACTION_SET_PAYOUT_COMPLAINT_SETTINGS = 'ACTION_SET_PAYOUT_COMPLAINT_SETTINGS';

export const ACTION_LOAD_PAYOUT_COMPLAINT_SETTINGS__REQUEST = 'ACTION_LOAD_PAYOUT_COMPLAINT_SETTINGS__REQUEST';
export const ACTION_LOAD_PAYOUT_COMPLAINT_SETTINGS__SUCCESS = 'ACTION_LOAD_PAYOUT_COMPLAINT_SETTINGS__SUCCESS';
export const ACTION_LOAD_PAYOUT_COMPLAINT_SETTINGS__FAIL = 'ACTION_LOAD_PAYOUT_COMPLAINT_SETTINGS__FAIL';

export const ACTION_SEND_PAYOUT_COMPLAINT__REQUEST = 'ACTION_SEND_PAYOUT_COMPLAINT__REQUEST';
export const ACTION_SEND_PAYOUT_COMPLAINT__SUCCESS = 'ACTION_SEND_PAYOUT_COMPLAINT__SUCCESS';
export const ACTION_SEND_PAYOUT_COMPLAINT__FAIL = 'ACTION_SEND_PAYOUT_COMPLAINT__FAIL';

export const ACTION_DELETE_PAYOUT_REQUEST_NOTICE__REQUEST = 'ACTION_DELETE_PAYOUT_REQUEST_NOTICE__REQUEST';
export const ACTION_DELETE_PAYOUT_REQUEST_NOTICE__SUCCESS = 'ACTION_DELETE_PAYOUT_REQUEST_NOTICE__SUCCESS';
export const ACTION_DELETE_PAYOUT_REQUEST_NOTICE__FAIL = 'ACTION_DELETE_PAYOUT_REQUEST_NOTICE__FAIL';

export const ACTION_LOAD_LATEST_SALES__REQUEST = 'ACTION_LOAD_LATEST_SALES__REQUEST';
export const ACTION_LOAD_LATEST_SALES__SUCCESS = 'ACTION_LOAD_LATEST_SALES__SUCCESS';
export const ACTION_LOAD_LATEST_SALES__FAIL = 'ACTION_LOAD_LATEST_SALES__FAIL';

export const ACTION_CANCEL_BANK_ACCOUNT__REQUEST = 'ACTION_CANCEL_BANK_ACCOUNT__REQUEST';
export const ACTION_CANCEL_BANK_ACCOUNT__SUCCESS = 'ACTION_CANCEL_BANK_ACCOUNT__SUCCESS';
export const ACTION_CANCEL_BANK_ACCOUNT__FAIL = 'ACTION_CANCEL_BANK_ACCOUNT__FAIL';

export const ACTION_LOAD_BANK_ACCOUNT_FILE__REQUEST = 'ACTION_LOAD_BANK_ACCOUNT_FILE__REQUEST';
export const ACTION_LOAD_BANK_ACCOUNT_FILE__SUCCESS = 'ACTION_LOAD_BANK_ACCOUNT_FILE__SUCCESS';
export const ACTION_LOAD_BANK_ACCOUNT_FILE__FAIL = 'ACTION_LOAD_BANK_ACCOUNT_FILE__FAIL';

export const ACTION_SET_IS_AMOUNT_RESTRICTED = 'ACTION_SET_IS_AMOUNT_RESTRICTED';

export const ACTION_ADD_REVENUE_CALCULATIONS = 'ACTION_ADD_REVENUE_CALCULATIONS';
