import {
  ACTION_BUY_CREDITS_BY_BANK_TRANSFER__SUCCESS,
  ACTION_BUY_CREDITS_LOCALLY__SUCCESS,
  ACTION_CONFIRM_BANK_WIRE__REQUEST,
  ACTION_CONFIRM_BANK_WIRE__SUCCESS,
  ACTION_CONFIRM_BANK_WIRE__FAIL,
} from '../../actionTypes';

import { DEPOSITS_GRID_NAME } from '../../constants/deposit';

import { actionLoadDepositsRequest } from '../../actions/depositsGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  DEPOSITS_GRID_NAME,
  actionLoadDepositsRequest,
  [ACTION_CONFIRM_BANK_WIRE__REQUEST],
  [ACTION_CONFIRM_BANK_WIRE__FAIL],
  [
    ACTION_CONFIRM_BANK_WIRE__SUCCESS,
    ACTION_BUY_CREDITS_BY_BANK_TRANSFER__SUCCESS,
    ACTION_BUY_CREDITS_LOCALLY__SUCCESS,
  ]
);
