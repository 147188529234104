import {
  ACTION_CREATE_STUDIO__REQUEST,
  ACTION_CREATE_STUDIO__SUCCESS,
  ACTION_CREATE_STUDIO__FAIL,
  ACTION_DELETE_STUDIO__REQUEST,
  ACTION_DELETE_STUDIO__SUCCESS,
  ACTION_DELETE_STUDIO__FAIL,
  ACTION_UPDATE_STUDIO__REQUEST,
  ACTION_UPDATE_STUDIO__SUCCESS,
  ACTION_UPDATE_STUDIO__FAIL,
} from '../../actionTypes';

import { STUDIOS_GRID_NAME } from '../../constants/studio';

import { actionLoadStudiosRequest } from '../../actions/studiosGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  STUDIOS_GRID_NAME,
  actionLoadStudiosRequest,
  [ACTION_CREATE_STUDIO__REQUEST, ACTION_DELETE_STUDIO__REQUEST, ACTION_UPDATE_STUDIO__REQUEST],
  [ACTION_CREATE_STUDIO__FAIL, ACTION_DELETE_STUDIO__FAIL, ACTION_UPDATE_STUDIO__FAIL],
  [ACTION_CREATE_STUDIO__SUCCESS, ACTION_DELETE_STUDIO__SUCCESS, ACTION_UPDATE_STUDIO__SUCCESS]
);
