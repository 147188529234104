import { combineReducers } from 'redux';

import assignModelToStudioError from './assignModelToStudioError';
import createStudioError from './createStudioError';
import deleteStudioError from './deleteStudioError';
import loadMasterModelError from './loadMasterModelError';
import loadStudiosError from './loadStudiosError';
import updateModelStatus from './updateModelStatus';
import updateStudioError from './updateStudioError';


export default combineReducers({
  assignModelToStudioError,
  createStudioError,
  deleteStudioError,
  loadMasterModelError,
  loadStudiosError,
  updateModelStatus,
  updateStudioError,
});
