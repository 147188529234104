import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import classnames from 'classnames';

import { useIntl } from 'react-redux-app/lib/modules/i18n/hooks';
import {
  useIsBlueChecked,
  useIsModel,
  useUserCredits,
  useUserNick,
  useUserRevenue,
} from '../../../auth/hooks';

import { getAccountSettingsUrl, getCreditsUrl, getRevenueUrl } from '../../utils/url';

import { ACCOUNT_TAB__BLUE_CHECK } from '../../../account/constants/tabs';
import { DEPOSITS_TAB__SUMMARY } from '../../../deposit/constants/tabs';
import { REVENUE_TAB__SUMMARY } from '../../../revenue/constants/tabs';

import EllipsisText from '../../../core/components/EllipsisText';
import BlueCheckContainer from '../../../core/components/blueCheck/BlueCheckContainer';
import LangLink from '../../../core/i18n/components/LangLink';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { USER_MENU_HEADER } from '../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


const themeApi = [
  'userMenuHeader',
  'userMenuHeader__credits',
  'userMenuHeader__nickWrapper',
  'userMenuHeader__nick',
  'userMenuHeader__revenue',
];

const propTypes = {
  className: PropTypes.string,

  theme: theming.getThemePropTypesShape(themeApi).isRequired,
};

const UserMenuHeader = ({ className = null, theme }) => {
  const nick = useUserNick();
  const credits = useUserCredits();
  const revenue = useUserRevenue();
  const isModel = useIsModel();
  const isBlueChecked = useIsBlueChecked();

  const { formatMessage: fm } = useIntl();

  return (
    <div className={classnames(theme.userMenuHeader, className)}>
      {credits > 0 && (
        <DropdownItem
          tag={LangLink}
          to={getCreditsUrl(null, DEPOSITS_TAB__SUMMARY)}
          title={fm({ id: 'msg.credits' })}
          className={theme.userMenuHeader__credits}
        >
          {`${credits} CRD`}
        </DropdownItem>
      )}

      <DropdownItem
        tag={LangLink}
        to={getAccountSettingsUrl(null, isModel && ACCOUNT_TAB__BLUE_CHECK)}
        className={theme.userMenuHeader__nickWrapper}
      >
        <BlueCheckContainer
          content={<EllipsisText text={nick} className={theme.userMenuHeader__nick} />}
          blueCheckProps={{ isBlueChecked, showRed: isModel }}
        />
      </DropdownItem>

      {revenue > 0 && (
        <DropdownItem
          tag={LangLink}
          to={getRevenueUrl(null, REVENUE_TAB__SUMMARY)}
          title={fm({ id: 'msg.revenue' })}
          className={theme.userMenuHeader__revenue}
        >
          {`${revenue} CRD`}
        </DropdownItem>
      )}
    </div>
  );
};

UserMenuHeader.propTypes = propTypes;

export default theming.theme(
  USER_MENU_HEADER,
  baseCssJson,
  themeApi
)(UserMenuHeader);
