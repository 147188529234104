import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';
import { getNotFoundUrl } from '../../../app/utils/url';
import { getLandingPage } from '../../utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import { landingPageToFilterMap } from '../../constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getLoadChatGalleryModelsError } from '../../../chatGallery/selectors/models';
import { getLoadPartialInitSettingsError } from '../../../initSettings/selectors/errors';
import { getLoadLandingPageError } from '../../selectors/errors';

import { actionLoadChatGalleryModelsRequest } from '../../../chatGallery/actions/loadChatGalleryModels';
import { actionLoadThumbedVideoRequest } from '../../../initSettings/actions/loadPartialInitSettings';
import { actionLoadLandingPageRequest } from '../../actions/loadLandingPage';


export default ({ getState, dispatch }) => (
  function loadLandingPageHandler(nextState, successCallback, errorCallback) {
    Promise.all([
      dispatch(actionLoadLandingPageRequest()),
      dispatch(actionLoadChatGalleryModelsRequest(
        landingPageToFilterMap[getLandingPage(nextState.location)], false, 12
      )),
      dispatch(actionLoadThumbedVideoRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const loadLandingPageError = getLoadLandingPageError(getState());
        if (loadLandingPageError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadLandingPageError.globalError, errorCallback
          )) {
            errorCallback(getNotFoundUrl(getCurrentLangForUrl(getState())));
          }
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError } of [
          getLoadChatGalleryModelsError(getState()),
          getLoadPartialInitSettingsError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadLandingPageHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
