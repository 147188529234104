import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';
import convertApiErrorsToFinalFormErrors from '../../../core/api/utils/convertApiErrorsToFinalFormErrors';
import { getFilter } from '../../../core/loadableItems/utils';
import { getErostroiesUrl } from '../../../app/utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import { CATEGORY_QUERY_PARAM } from '../../constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getLoadLandingPageError } from '../../../landingPage/selectors/errors';
import {
  getErostoryCategories,
  getLoadErostoriesError,
  getLoadErostoryCategoriesError,
} from '../../selectors/erostory';

import { actionLoadLandingPageRequest } from '../../../landingPage/actions/loadLandingPage';
import { actionLoadErostoriesRequest, actionLoadErostoryCategoriesRequest } from '../../actions/erostory';


export default ({ getState, dispatch }) => (
  function loadErostoriesHandler(nextState, successCallback, errorCallback) {
    const selectedCategory = getFilter(nextState.location)[CATEGORY_QUERY_PARAM];
    const erostoriesUrl = getErostroiesUrl(getCurrentLangForUrl(getState()));

    Promise.all([
      dispatch(actionLoadLandingPageRequest()),
      dispatch(actionLoadErostoriesRequest()),
      dispatch(actionLoadErostoryCategoriesRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const loadLandingPageError = getLoadLandingPageError(getState());
        if (maybeHandleCriticalError(
          nextState.location, loadLandingPageError?.globalError, errorCallback
        )) {
          return;
        }

        const loadErostoriesError = getLoadErostoriesError(getState());
        if (loadErostoriesError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadErostoriesError.globalError, errorCallback
          )) {
            const errors = convertApiErrorsToFinalFormErrors(loadErostoriesError);
            errorCallback(errors.orderBy ? erostoriesUrl : INTERNAL_ERROR_URI);
          }
          return;
        }

        const loadErostoryCategoriesError = getLoadErostoryCategoriesError(getState());
        if (loadErostoryCategoriesError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadErostoryCategoriesError.globalError, errorCallback
          )) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        if (selectedCategory && getErostoryCategories(getState()).filter(
          category => category.id === parseInt(selectedCategory, 10)
        ).length === 0) {
          errorCallback(erostoriesUrl);
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadErostoriesHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
