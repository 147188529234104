import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getLoadLandingPageError } from '../../../landingPage/selectors/errors';
import { getLoadInterviewsError } from '../../selectors/interview';

import { actionLoadLandingPageRequest } from '../../../landingPage/actions/loadLandingPage';
import { actionLoadInterviewsRequest } from '../../actions/loadInterviews';


export default ({ getState, dispatch }) => (
  function loadInterviewsHandler(nextState, successCallback, errorCallback) {
    Promise.all([
      dispatch(actionLoadLandingPageRequest()),
      dispatch(actionLoadInterviewsRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const loadLandingPageError = getLoadLandingPageError(getState());
        if (maybeHandleCriticalError(
          nextState.location, loadLandingPageError?.globalError, errorCallback
        )) {
          return;
        }

        const loadInterviewsError = getLoadInterviewsError(getState());
        if (loadInterviewsError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadInterviewsError.globalError, errorCallback
          )) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadInterviewsHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
