import {
  ACTION_LOAD_PARTIAL_INIT_SETTINGS__REQUEST,
  ACTION_LOAD_PARTIAL_INIT_SETTINGS__SUCCESS,
  ACTION_LOAD_PARTIAL_INIT_SETTINGS__FAIL,
} from '../actionTypes';

import { getInitSettings } from '../selectors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddInitSettings } from './addInitSettings';


const reloadableFields = ['chatGalleryFilters', 'videoSettings', 'photoSettings', 'storySettings'];

export const actionLoadPartialInitSettingsSuccess = () => ({
  type: ACTION_LOAD_PARTIAL_INIT_SETTINGS__SUCCESS,
});

export const actionLoadPartialInitSettingsFail = error => ({
  type: ACTION_LOAD_PARTIAL_INIT_SETTINGS__FAIL,
  payload: { error },
});

const actionLoadPartialInitSettingsRequest = fields => (
  (dispatch, getState, { apiClientHelper }) => {
    const notLoadedFields = fields.filter(
      field => reloadableFields.includes(field) || !getInitSettings(getState(), field)
    );

    if (notLoadedFields.length === 0) {
      return new Promise(resolve => { resolve(); });
    }

    dispatch({
      type: ACTION_LOAD_PARTIAL_INIT_SETTINGS__REQUEST,
    });

    return apiClientHelper.get(
      'init/partial',
      { params: { fields: notLoadedFields.join() } }
    ).then(
      settings => {
        dispatch(actionAddInitSettings(settings));

        dispatch(actionLoadPartialInitSettingsSuccess());
      },
      error => {
        dispatch(actionLoadPartialInitSettingsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

export const actionLoadAllowedSexPreferencesAndPreviewSettingsRequest = () => (
  actionLoadPartialInitSettingsRequest(['sexPreferences', 'thumbedVideo'])
);

export const actionLoadAccountSettingsRequest = () => (
  actionLoadPartialInitSettingsRequest([
    'sexPreferences',
    'countries',
    'videoSettings',
    'photoSettings',
  ])
);

export const actionLoadPhotoSettingsRequest = () => (
  actionLoadPartialInitSettingsRequest(['photoSettings'])
);

export const actionLoadProfileSettingsRequest = () => (
  actionLoadPartialInitSettingsRequest(['sexPreferences', 'countries', 'chat'])
);

export const actionLoadRivstreamerFileUrlRequest = () => (
  actionLoadPartialInitSettingsRequest(['rivstreamerFileUrl'])
);

export const actionLoadWebcamDefaultsRequest = () => (
  actionLoadPartialInitSettingsRequest(['webcamSettings'])
);

export const actionLoadStepSettingsRequest = () => (
  actionLoadPartialInitSettingsRequest([
    'registrationStepsSettings',
    'documentUploadSettings',
    'countries',
    'registrationCountries',
  ])
);

export const actionLoadPartnerSettingsRequest = () => (
  actionLoadPartialInitSettingsRequest([
    'registrationStepsSettings',
    'countries',
  ])
);

export const actionLoadSiteFeedbackSettingsRequest = () => (
  actionLoadPartialInitSettingsRequest(['siteFeedback'])
);

export const actionLoadChatGallerySettingsRequest = () => (
  actionLoadPartialInitSettingsRequest([
    'sexPreferences',
    'thumbedVideo',
    'chatGalleryFilters',
    'weeklyEvent',
    'welcomeBonus',
    'cryptoBonus',
  ])
);

export const actionLoadOwnSaleItemSettingsRequest = () => (
  actionLoadPartialInitSettingsRequest(['videoSettings', 'photoSettings', 'storySettings'])
);

export const actionLoadDocumentUploadSettingsRequest = () => (
  actionLoadPartialInitSettingsRequest(['documentUploadSettings'])
);

export const actionLoadAppointmentCancelReasonsRequest = () => (
  actionLoadPartialInitSettingsRequest(['appointmentCancelReasons'])
);

export const actionLoadThumbedVideoRequest = () => (
  actionLoadPartialInitSettingsRequest(['thumbedVideo'])
);

export const actionLoadSitemapSettingsRequest = () => (
  actionLoadPartialInitSettingsRequest(['sexPreferences', 'chatGalleryFilters'])
);

export const actionLoadPostSettingsRequest = () => (
  actionLoadPartialInitSettingsRequest(['postSettings'])
);

export const actionLoadBonusesRequest = () => (
  actionLoadPartialInitSettingsRequest(['welcomeBonus', 'cryptoBonus'])
);

export const actionLoadSiteEventsRequest = () => (
  actionLoadPartialInitSettingsRequest(['siteEvents'])
);

export const actionLoadCountriesRequest = () => (
  actionLoadPartialInitSettingsRequest(['countries'])
);
