import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_DELETE_PARTNER__REQUEST,
  ACTION_DELETE_PARTNER__SUCCESS,
  ACTION_DELETE_PARTNER__FAIL,
  ACTION_UPLOAD_PARTNER_FILE__SUCCESS,
  ACTION_CLEAR_PARTNER_STATE,
  ACTION_ADD_PARTNERS,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_PARTNERS:
      return { ...state, ...action.payload.partners };

    case ACTION_DELETE_PARTNER__SUCCESS:
      return _omit(state, action.payload.partnerId);

    default:
      return state;
  }
};

const isPartnerBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_PARTNER__REQUEST:
      return { ...state, [action.payload.partnerId]: true };

    case ACTION_DELETE_PARTNER__SUCCESS:
    case ACTION_DELETE_PARTNER__FAIL:
      return _omit(state, action.payload.partnerId);

    default:
      return state;
  }
};

const files = (state = {}, action) => {
  switch (action.type) {
    case ACTION_UPLOAD_PARTNER_FILE__SUCCESS: {
      const {
        idPhotoType,
        fileInfo,
      } = action.payload;

      return { ...state, [idPhotoType]: fileInfo };
    }

    case ACTION_CLEAR_PARTNER_STATE:
      return {};

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  files,
  isPartnerBeingDeleted,
  errors,
});
