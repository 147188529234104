import { createSelector } from 'reselect';
import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';
import { filterSaleTypeLangKeys } from '../../balance/utils';
import { getChatStatus, getType } from '../utils';

import { CHAT_STATUS__OFFLINE } from '../../core/constants/chat';
import { modelSearchSaleTypeLangKeys } from '../constants';

import { getIsPrivateContentForbidden, getIsMobileDevice } from '../../initSettings/selectors';
import { getPreviewModelId } from './preview';


const modelsSelector = state => _get(state, 'chatGallery.models');
const getModel = (state, modelId) => _get(modelsSelector(state), `byId.${modelId}`);
const modelChatStatusesSelector = state => _get(modelsSelector(state), 'chatStatus');
export const getNick = (state, modelId) => _get(getModel(state, modelId), 'nick');
export const getAge = (state, modelId) => _get(getModel(state, modelId), 'age');
export const getLanguages = (state, modelId) => _get(getModel(state, modelId), 'languages');
export const getThumbUrl = (state, modelId) => _get(getModel(state, modelId), 'imageUrl');
export const getIsBlueChecked = (state, modelId) => _get(getModel(state, modelId), 'isBlueChecked');
export const getHasToys = (state, modelId) => _get(getModel(state, modelId), 'hasToys');

const isTypeAllowedSelector = (state, modelId, filter) => (
  _get(modelsSelector(state), `isTypeAllowed.${modelId}.${getType(filter)}`)
);
export const getAreTypesAllowed = (state, modelId, filters) => (
  filters.reduce(
    (areTypesAllowed, filter) => areTypesAllowed && isTypeAllowedSelector(state, modelId, filter),
    true
  )
);

const getChatStatusByRivModelChatStatus = rivModelChatStatus => getChatStatus(
  rivModelChatStatus?.status, rivModelChatStatus?.stream
);
export const getStatus = (state, modelId) => getChatStatusByRivModelChatStatus(
  modelChatStatusesSelector(state)[modelId]
);

export const getChatGalleryModelIds = state => _get(modelsSelector(state), 'order');

export const getOnlineModelsCount = createSelector(
  getChatGalleryModelIds,
  modelChatStatusesSelector,
  (modelIds, modelChatStatuses) => modelIds.filter(modelId => (
    modelId > 0
    && getChatStatusByRivModelChatStatus(modelChatStatuses[modelId]) !== CHAT_STATUS__OFFLINE
  )).length
);

export const getIsModelLoaded = (state, modelId) => !!getModel(state, modelId);
export const getIsModelInGallery = (state, modelId) => (
  getChatGalleryModelIds(state).includes(modelId)
);

export const getFirstOfflineModelPosition = createSelector(
  getChatGalleryModelIds,
  modelChatStatusesSelector,
  (modelIds, modelChatStatuses) => {
    for (let i = 0; i < modelIds.length; i++) {
      if (
        modelIds[i] > 0
        && getChatStatusByRivModelChatStatus(
          modelChatStatuses[modelIds[i]]
        ) === CHAT_STATUS__OFFLINE
      ) {
        return i;
      }
    }

    return null;
  }
);

export const getLastOnlineModelPosition = createSelector(
  getChatGalleryModelIds,
  modelChatStatusesSelector,
  (modelIds, modelChatStatuses) => {
    for (let i = modelIds.length - 1; i >= 0; i--) {
      if (
        modelIds[i] > 0
        && getChatStatusByRivModelChatStatus(
          modelChatStatuses[modelIds[i]]
        ) !== CHAT_STATUS__OFFLINE
      ) {
        return i;
      }
    }

    return null;
  }
);

export const getModelIdsToSwap = state => {
  const firstOfflineModelPosition = getFirstOfflineModelPosition(state);
  const lastOnlineModelPosition = getLastOnlineModelPosition(state);

  if (
    firstOfflineModelPosition === null
    || lastOnlineModelPosition === null
    || firstOfflineModelPosition - lastOnlineModelPosition === 1
  ) {
    return null;
  }

  const modelIds = getChatGalleryModelIds(state);
  const modelId1 = modelIds[firstOfflineModelPosition];
  const modelId2 = modelIds[lastOnlineModelPosition];

  const currentModelId = getPreviewModelId(state);

  if ([modelId1, modelId2].includes(currentModelId)) {
    return null;
  }

  const bonusIdPosition = modelIds.findIndex(modelId => modelId <= 0);

  if (
    bonusIdPosition !== -1
    && (firstOfflineModelPosition > bonusIdPosition && bonusIdPosition > lastOnlineModelPosition)
  ) {
    return null;
  }

  return [lastOnlineModelPosition, firstOfflineModelPosition];
};

export const getModelLimit = state => (getIsMobileDevice(state) ? 36 : 84);

export const getAllowedModelSearchSaleTypeLangKeys = createSelector(
  getIsPrivateContentForbidden,
  isPrivateContentForbidden => filterSaleTypeLangKeys(
    modelSearchSaleTypeLangKeys, isPrivateContentForbidden
  )
);

export const getWeekendImage = state => _get(modelsSelector(state), 'weekendImage');
export const getCreditsBonus = (state, type) => (
  _get(modelsSelector(state), `creditsBonusByType.${type}`)
);

const modelIdsSelector = state => _get(modelsSelector(state), 'models');
export const getModelIds = state => _get(modelIdsSelector(state), 'items');
export const getModelsTotalCount = state => _get(modelIdsSelector(state), 'count');
const getModelsLimit = state => _get(modelIdsSelector(state), 'limit');
export const getIsLastModelLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getModelIds, getModelsTotalCount, getModelsLimit
);
export const getModelTotalPagesCount = state => getTotalPagesCount(
  state, getModelsTotalCount, getModelsLimit
);

export const getModelPaginationMode = state => _get(modelsSelector(state), 'paginationMode');

const errorsSelector = state => _get(modelsSelector(state), 'errors');
export const getLoadChatGalleryModelError = state => (
  _get(errorsSelector(state), 'loadChatGalleryModelError')
);
export const getLoadChatGalleryModelsError = state => (
  _get(errorsSelector(state), 'loadChatGalleryModelsError')
);
export const getLoadModelsError = state => (
  _get(errorsSelector(state), 'loadModelsError')
);
export const getSaveWeeklyEventPrizeError = state => (
  _get(errorsSelector(state), 'saveWeeklyEventPrizeError')
);
