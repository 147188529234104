import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import { PEER__BAN_STAFF } from '../../constants';

import { getIsBanPermanent, getIsUnbanRequestSent } from '../../../auth/selectors';
import { getBanStaffUserId } from '../../../initSettings/selectors';
import { getLoadDialogMessagesError, getLoadPeerError } from '../../selectors/errors';

import { actionClearDialogMessageState, actionLoadDialogMessagesRequest } from '../../actions';
import { actionLoadPeerRequest } from '../../actions/loadPeer';


export default (peer = null) => ({ getState, dispatch }) => (
  function loadDialogHandler(nextState, successCallback, errorCallback) {
    let { peerId } = nextState.params;
    if (peer === PEER__BAN_STAFF) {
      if (!getIsBanPermanent(getState()) || !getIsUnbanRequestSent(getState())) {
        successCallback();
        return;
      }

      peerId = getBanStaffUserId(getState());
    }

    dispatch(actionClearDialogMessageState());

    Promise.all([
      dispatch(actionLoadPeerRequest(peerId)),
      dispatch(actionLoadDialogMessagesRequest(peerId)),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError } of [
          getLoadPeerError(getState()),
          getLoadDialogMessagesError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadDialogHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
