import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import classnames from 'classnames';

import { useIsAuthenticated } from '../../../auth/hooks';
import { useIsStudioOrModelHub } from '../../../initSettings/hooks';

import LangLink from '../../../core/i18n/components/LangLink';
import PaidRouletteLink from '../../../auth/components/PaidRouletteLink';
import AuthButtons from '../AuthButtons';
import Banner from '../Banner';
import DropdownMenu from '../DropdownMenu';
import FavoritesMenuLink from '../FavoritesMenuLink';
import LanguageSelector from '../LanguageSelector';
import Logo from '../Logo';
import Menu from '../Menu';
import MessagesMenuLink from '../MessagesMenuLink';
import ReturnToMasterButton from '../ReturnToMasterButton';
import UserDropdownMenu from '../UserDropdownMenu';
import VideochatLink from '../VideochatLink';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { HEADER } from '../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


const themeApi = [
  'container',
  'bar',
  'logo',
  'menu',
  'menu__item',
  'videochat',
  'button',
  'hiddenToXL',
  'hiddenFromXL',
  'hiddenToXXL',
];

const propTypes = {
  theme: theming.getThemePropTypesShape(themeApi).isRequired,
};

const Header = ({ theme }) => {
  const isAuthenticated = useIsAuthenticated();
  const isStudioOrModelHub = useIsStudioOrModelHub();

  return (
    <Container fluid className={theme.container}>
      <Row className={theme.bar}>
        <Col className={theme.logo}>
          <Logo />
        </Col>

        <Col className={theme.menu}>
          {!isStudioOrModelHub ? (
            <>
              <Banner className={classnames(theme.menu__item, theme.hiddenToXXL)} />

              <PaidRouletteLink className={theme.menu__item} />

              <VideochatLink
                linkComponent={LangLink}
                className={classnames(theme.menu__item, theme.videochat, theme.hiddenFromXL)}
              />

              <FavoritesMenuLink className={classnames(theme.menu__item, theme.hiddenFromXL)} />
              <MessagesMenuLink className={classnames(theme.menu__item, theme.hiddenFromXL)} />

              <LanguageSelector className={classnames(theme.menu__item, theme.hiddenFromXL)} />

              <DropdownMenu className={theme.hiddenFromXL} />

              <Menu className={theme.hiddenToXL} />
            </>
          ) : (
            <>
              <ReturnToMasterButton className={classnames(theme.menu__item, theme.button)} />

              <VideochatLink
                linkComponent={LangLink}
                className={classnames(theme.menu__item, theme.videochat)}
              />

              <LanguageSelector className={theme.menu__item} />

              {isAuthenticated ? <UserDropdownMenu /> : <AuthButtons className={theme.button} />}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

Header.propTypes = propTypes;

export default theming.theme(
  HEADER,
  baseCssJson,
  themeApi
)(Header);
