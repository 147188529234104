import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import classnames from 'classnames';

import useCurrentLangForUrl from '../../../core/i18n/hooks/useCurrentLangForUrl';
import useShowModal from '../../../core/hooks/useShowModal';
import { useIsModelHub, useIsStudio } from '../../../initSettings/hooks';

import { l } from 'react-redux-app/lib/modules/i18n/utils';
import { getRegisterModelUrl, getRegisterMasterUrl } from '../../utils/url';

import { actionShowLoginDialog } from '../../../auth/actions/dialogs/loginDialog';
import { actionShowRegistrationDialog } from '../../../auth/actions/dialogs/registrationDialog';

import { Button } from 'react-redux-app/lib/modules/button/components';
import WhiteButton from '../../../core/components/buttons/WhiteButton';
import MenuLink from '../MenuLink';

import styles from './styles/index.css.json';


const propTypes = {
  isLoginLink: PropTypes.bool,
  isDropdownItem: PropTypes.bool,
  className: PropTypes.string,
};

const AuthButtons = ({ isLoginLink = false, isDropdownItem = false, className = null }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const langForUrl = useCurrentLangForUrl();
  const isModelHub = useIsModelHub();
  const isStudio = useIsStudio();

  const showModal = useShowModal();

  const getButton = (ButtonComponent, onClick, valueLangKey) => {
    let BComponent = ButtonComponent;
    const buttonProps = {
      type: 'button',
      onClick,
      className: classnames(styles.button, className),
    };

    if (isDropdownItem) {
      BComponent = DropdownItem;
      buttonProps.tag = ButtonComponent;
    }

    return (
      <BComponent {...buttonProps}>
        {l(valueLangKey)}
      </BComponent>
    );
  };

  return (
    <>
      {!isLoginLink ? (
        getButton(WhiteButton, () => { dispatch(actionShowLoginDialog()); }, 'action.log-in')
      ) : (
        <MenuLink to="/login" onClick={showModal(actionShowLoginDialog())} className={className}>
          {l('action.log-in')}
        </MenuLink>
      )}

      {getButton(
        Button,
        () => {
          if (isModelHub) {
            navigate(getRegisterModelUrl(langForUrl));
          } else if (isStudio) {
            navigate(getRegisterMasterUrl(langForUrl));
          } else {
            dispatch(actionShowRegistrationDialog());
          }
        },
        'action.register'
      )}
    </>
  );
};

AuthButtons.propTypes = propTypes;

export default AuthButtons;
