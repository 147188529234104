import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getIsCommon, getIsModel } from '../../../auth/selectors';
import { getLoadPartialInitSettingsError } from '../../../initSettings/selectors/errors';
import { getLoadPresentationsError } from '../../../ownSaleItem/selectors/presentation';
import { getLoadProfilePhotosError } from '../../../ownSaleItem/selectors/profilePhoto';
import {
  getLoadAccountError,
  getLoadSocialSettingsError,
  getLoadPreferencesError,
  getLoadPrivacyError,
  getLoadPrivacyAddressInformationError,
  getLoadModelProfileError,
  getLoadBlueCheckSettingsError,
} from '../../selectors/errors';

import { actionLoadAccountSettingsRequest } from '../../../initSettings/actions/loadPartialInitSettings';
import { actionLoadPresentationsRequest } from '../../../ownSaleItem/actions/loadPresentations';
import { actionLoadProfilePhotosRequest } from '../../../ownSaleItem/actions/loadProfilePhotos';
import { actionLoadAccountRequest } from '../../actions/loadAccount';
import { actionLoadBlueCheckSettingsRequest } from '../../actions/loadBlueCheckSettings';
import { actionLoadModelProfileRequest } from '../../actions/loadModelProfile';
import { actionLoadPreferencesRequest } from '../../actions/loadPreferences';
import { actionLoadPrivacyRequest } from '../../actions/loadPrivacy';
import { actionLoadPrivacyAddressInformationRequest } from '../../actions/loadPrivacyAddressInformation';
import { actionLoadSocialSettingsRequest } from '../../actions/loadSocialSettings';


export default ({ getState, dispatch }) => (
  function loadAccountHandler(nextState, successCallback, errorCallback) {
    const isCommon = getIsCommon(getState());
    const isModel = getIsModel(getState());

    Promise.all([
      dispatch(actionLoadAccountRequest()),
      dispatch(actionLoadSocialSettingsRequest()),
      dispatch(actionLoadAccountSettingsRequest()),
      dispatch(actionLoadProfilePhotosRequest()),
      isCommon && dispatch(actionLoadPrivacyRequest()),
      isCommon && dispatch(actionLoadPreferencesRequest()),
      isModel && dispatch(actionLoadPrivacyAddressInformationRequest()),
      isModel && dispatch(actionLoadModelProfileRequest()),
      isModel && dispatch(actionLoadPresentationsRequest()),
      isModel && dispatch(actionLoadBlueCheckSettingsRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError } of [
          getLoadAccountError(getState()),
          getLoadSocialSettingsError(getState()),
          getLoadPartialInitSettingsError(getState()),
          getLoadProfilePhotosError(getState()),
          getLoadPrivacyError(getState()),
          getLoadPreferencesError(getState()),
          getLoadPrivacyAddressInformationError(getState()),
          getLoadModelProfileError(getState()),
          getLoadPresentationsError(getState()),
          getLoadBlueCheckSettingsError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadAccountHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
