export const ACTION_ADD_BALANCE_ADJUSTMENTS = 'ACTION_ADD_BALANCE_ADJUSTMENTS';
export const ACTION_ADD_EXPIRED_CREDITS = 'ACTION_ADD_EXPIRED_CREDITS';
export const ACTION_ADD_FEES = 'ACTION_ADD_FEES';
export const ACTION_ADD_LOSSES_COMPENSATIONS = 'ACTION_ADD_LOSSES_COMPENSATIONS';
export const ACTION_ADD_PURCHASED_SERVICES = 'ACTION_ADD_PURCHASED_SERVICES';
export const ACTION_ADD_PURCHASES = 'ACTION_ADD_PURCHASES';
export const ACTION_ADD_REVENUE_CONVERSIONS = 'ACTION_ADD_REVENUE_CONVERSATIONS';
export const ACTION_ADD_SALES = 'ACTION_ADD_SALES';
export const ACTION_ADD_STUDIO_SALES = 'ACTION_ADD_STUDIO_SALES';

export const ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__REQUEST = 'ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__REQUEST';
export const ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__SUCCESS = 'ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__SUCCESS';
export const ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__FAIL = 'ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__FAIL';
