import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';
import {
  getMediaViewerCloseUrl,
  getActionLoadMediaViewerItemsRequest,
  getActionLoadMediaViewerItemInfoRequest,
} from '../../utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import { NOT_FOUND } from '../../../core/api/constants/errors';

import {
  getLoadMediaViewerItemsError,
  getLoadMediaViewerItemInfoError,
} from '../../selectors/errors';


export default ({ getState, dispatch }) => (
  function loadMediaViewerDataHandler(nextState, successCallback, errorCallback) {
    const actionLoadMediaViewerItemsRequest = (
      getActionLoadMediaViewerItemsRequest(nextState.location)
    );
    const actionLoadMediaViewerItemInfoRequest = (
      getActionLoadMediaViewerItemInfoRequest(nextState.location)
    );
    if (!actionLoadMediaViewerItemsRequest || !actionLoadMediaViewerItemInfoRequest) {
      successCallback();
      return;
    }

    Promise.all([
      dispatch(actionLoadMediaViewerItemsRequest()),
      dispatch(actionLoadMediaViewerItemInfoRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError } of [
          getLoadMediaViewerItemsError(getState()),
          getLoadMediaViewerItemInfoError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            errorCallback(globalError.code === NOT_FOUND
              ? getMediaViewerCloseUrl(nextState.location)
              : INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadMediaViewerDataHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
