import useDomains from './useDomains';
import useIsStudio from './useIsStudio';
import useCurrentLangForUrl from '../../core/i18n/hooks/useCurrentLangForUrl';

import { getRegisterMasterUrl } from '../../app/utils/url';

import { REGISTRATION_TYPE__MASTER } from '../../auth/constants';


export default () => {
  const langForUrl = useCurrentLangForUrl();
  const domains = useDomains();
  const isStudio = useIsStudio();

  return domains && !isStudio
    ? `https://${domains[REGISTRATION_TYPE__MASTER]}${getRegisterMasterUrl(langForUrl)}`
    : null;
};
