import { getIndexUrl } from '../../utils/url';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getIsAuthenticated, getIsIncompleteModelRegistration } from '../../../auth/selectors';


export default ({ getState, dispatch }) => (
  function modelIncompleteRegistrationRequiredHandler(nextState, successCallback, errorCallback) {
    if (!getIsAuthenticated(getState()) || !getIsIncompleteModelRegistration(getState())) {
      errorCallback(getIndexUrl(getCurrentLangForUrl(getState())));
    } else {
      successCallback();
    }
  }
);
