import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';
import { getNotFoundUrl } from '../../../app/utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getChatSettings } from '../../../initSettings/selectors';
import { getLoadPartialInitSettingsError } from '../../../initSettings/selectors/errors';
import { getLoadProfileError } from '../../selectors/errors';

import { actionLoadProfileSettingsRequest } from '../../../initSettings/actions/loadPartialInitSettings';
import { actionLoadProfileRequest } from '../../actions/loadProfile';


export default ({ getState, dispatch }) => (
  function loadProfileHandler(nextState, successCallback, errorCallback) {
    const id = parseInt(nextState.params.id, 10);

    Promise.all([
      dispatch(actionLoadProfileRequest(id)),
      dispatch(actionLoadProfileSettingsRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const loadProfileError = getLoadProfileError(getState());
        if (loadProfileError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadProfileError.globalError, errorCallback
          )) {
            errorCallback(getNotFoundUrl(getCurrentLangForUrl(getState())));
          }
          return;
        }

        const loadPartialInitSettingsError = getLoadPartialInitSettingsError(getState());
        if (loadPartialInitSettingsError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadPartialInitSettingsError.globalError, errorCallback
          )) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        if (!getChatSettings(getState())) {
          errorCallback(INTERNAL_ERROR_URI);
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadProfileHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
