export const ACTION_ADD_MODELS = 'ACTION_ADD_MODELS';
export const ACTION_ADD_STUDIOS = 'ACTION_ADD_STUDIOS';

export const ACTION_CREATE_STUDIO__REQUEST = 'ACTION_CREATE_STUDIO__REQUEST';
export const ACTION_CREATE_STUDIO__SUCCESS = 'ACTION_CREATE_STUDIO__SUCCESS';
export const ACTION_CREATE_STUDIO__FAIL = 'ACTION_CREATE_STUDIO__FAIL';

export const ACTION_DELETE_STUDIO__REQUEST = 'ACTION_DELETE_STUDIO__REQUEST';
export const ACTION_DELETE_STUDIO__SUCCESS = 'ACTION_DELETE_STUDIO__SUCCESS';
export const ACTION_DELETE_STUDIO__FAIL = 'ACTION_DELETE_STUDIO__FAIL';

export const ACTION_UPDATE_STUDIO__REQUEST = 'ACTION_UPDATE_STUDIO__REQUEST';
export const ACTION_UPDATE_STUDIO__SUCCESS = 'ACTION_UPDATE_STUDIO__SUCCESS';
export const ACTION_UPDATE_STUDIO__FAIL = 'ACTION_UPDATE_STUDIO__FAIL';

export const ACTION_UPDATE_MODEL_STATUS__REQUEST = 'ACTION_UPDATE_MODEL_STATUS__REQUEST';
export const ACTION_UPDATE_MODEL_STATUS__SUCCESS = 'ACTION_UPDATE_MODEL_STATUS__SUCCESS';
export const ACTION_UPDATE_MODEL_STATUS__FAIL = 'ACTION_UPDATE_MODEL_STATUS__FAIL';

export const ACTION_LOAD_MASTER_MODEL__REQUEST = 'ACTION_LOAD_MASTER_MODEL__REQUEST';
export const ACTION_LOAD_MASTER_MODEL__SUCCESS = 'ACTION_LOAD_MASTER_MODEL__SUCCESS';
export const ACTION_LOAD_MASTER_MODEL__FAIL = 'ACTION_LOAD_MASTER_MODEL__FAIL';

export const ACTION_LOAD_STUDIOS__REQUEST = 'ACTION_LOAD_STUDIOS__REQUEST';
export const ACTION_LOAD_STUDIOS__SUCCESS = 'ACTION_LOAD_STUDIOS__SUCCESS';
export const ACTION_LOAD_STUDIOS__FAIL = 'ACTION_LOAD_STUDIOS__FAIL';

export const ACTION_ASSIGN_MODEL_TO_STUDIO__REQUEST = 'ACTION_ASSIGN_MODEL_TO_STUDIO__REQUEST';
export const ACTION_ASSIGN_MODEL_TO_STUDIO__SUCCESS = 'ACTION_ASSIGN_MODEL_TO_STUDIO__SUCCESS';
export const ACTION_ASSIGN_MODEL_TO_STUDIO__FAIL = 'ACTION_ASSIGN_MODEL_TO_STUDIO__FAIL';
