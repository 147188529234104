export const DEPOSITS_TAB__PAYMENT = 'payment';
export const DEPOSITS_TAB__PURCHASED_CREDITS = 'purchasedCredits';
export const DEPOSITS_TAB__REWARD_REQUESTS = 'rewardRequests';
export const DEPOSITS_TAB__SUMMARY = 'summary';

export const depositsTabs = [
  DEPOSITS_TAB__PAYMENT,
  DEPOSITS_TAB__PURCHASED_CREDITS,
  DEPOSITS_TAB__SUMMARY,
  DEPOSITS_TAB__REWARD_REQUESTS,
];
