import PropTypes from 'prop-types';

import {
  depositStatuses,
  sections,
  methods,
  cryptoMethods,
  gatewayAnswers,
  notificationTypes,
  rewardRequestStatuses,
} from '../constants';
import { currencies } from '../../core/constants/currency';


export const depositStatusPropType = PropTypes.oneOf(depositStatuses);

export const currencyPropType = PropTypes.oneOf(currencies);

export const sectionPropType = PropTypes.oneOf(sections);
export const sectionsPropType = PropTypes.arrayOf(sectionPropType);

export const methodPropType = PropTypes.oneOf(methods);
export const methodsPropType = PropTypes.arrayOf(methodPropType);

export const cryptomethodPropType = PropTypes.oneOf(cryptoMethods);
export const cryptoMethodsPropType = PropTypes.arrayOf(cryptomethodPropType);

export const gatewayAnswerPropType = PropTypes.oneOf(gatewayAnswers);

export const notificationTypePropType = PropTypes.oneOf(notificationTypes);

export const rewardRequestStatusPropType = PropTypes.oneOf(rewardRequestStatuses);

export const creditPacksPropType = PropTypes.arrayOf(
  PropTypes.shape({
    creditPackId: PropTypes.number.isRequired,
    credits: PropTypes.number.isRequired,
    creditsBonus: PropTypes.number,
    price: PropTypes.string.isRequired,
    oldPrice: PropTypes.string,
    discountPercent: PropTypes.number.isRequired,
    siteCurrencyPrice: PropTypes.string,
  })
);

export const rewardDetailsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })
);
