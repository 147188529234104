import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getIsPrivateContentForbidden } from '../../../initSettings/selectors';
import { getLoadPartialInitSettingsError } from '../../../initSettings/selectors/errors';
import { getLoadErostoriesError } from '../../selectors/erostory';
import { getLoadPersonalContactsError } from '../../selectors/personalContact';
import { getLoadPrivatePhotosError } from '../../selectors/privatePhoto';
import { getLoadUnderwearError } from '../../selectors/underwear';
import { getLoadVideosError } from '../../selectors/video';

import { actionLoadOwnSaleItemSettingsRequest } from '../../../initSettings/actions/loadPartialInitSettings';
import { actionLoadOwnErostoriesRequest } from '../../actions/loadOwnErostories';
import { actionLoadPersonalContactsRequest } from '../../actions/loadPersonalContacts';
import { actionLoadOwnPrivatePhotosRequest } from '../../actions/loadOwnPrivatePhotos';
import { actionLoadOwnUnderwearRequest } from '../../actions/loadOwnUnderwear';
import { actionLoadOwnVideosRequest } from '../../actions/loadOwnVideos';


export default ({ getState, dispatch }) => (
  function loadItemsForSaleHandler(nextState, successCallback, errorCallback) {
    const isPrivateContentAllowed = !getIsPrivateContentForbidden(getState());

    Promise.all([
      isPrivateContentAllowed && dispatch(actionLoadOwnVideosRequest()),
      isPrivateContentAllowed && dispatch(actionLoadOwnPrivatePhotosRequest()),
      dispatch(actionLoadOwnErostoriesRequest()),
      dispatch(actionLoadOwnUnderwearRequest()),
      dispatch(actionLoadPersonalContactsRequest()),
      dispatch(actionLoadOwnSaleItemSettingsRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError } of [
          getLoadVideosError(getState()),
          getLoadPrivatePhotosError(getState()),
          getLoadErostoriesError(getState()),
          getLoadUnderwearError(getState()),
          getLoadPersonalContactsError(getState()),
          getLoadPartialInitSettingsError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadItemsForSaleHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
