// If error happened here,
// it means that application is not working completely.
// We have to make redirect to INTERNAL_ERROR_URI in this case.
// NOT to '/' page, because it can lead to redirect loop.

import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getAreTranslationsLoaded } from 'react-redux-app/lib/modules/i18n/selectors';
import { getLoadInitSettingsError } from '../../selectors/errors';

import { actionLoadInitSettingsRequest } from '../../actions/loadInitSettings';


export default ({ getState, dispatch }) => (
  function initSettingsHandler(nextState, successCallback, errorCallback) {
    dispatch(
      actionLoadInitSettingsRequest()
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = getLoadInitSettingsError(getState());
        if (maybeHandleCriticalError(nextState.location, error?.globalError, errorCallback)) {
          return;
        }

        if (getAreTranslationsLoaded(getState())) {
          successCallback();
          return;
        }

        console.consoleError(
          `initHandler. Unable to initialize application. Make redirect to ${INTERNAL_ERROR_URI}`
        );
        errorCallback(INTERNAL_ERROR_URI);
      }
    ).catch(error => {
      console.consoleError('initSettingsHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
